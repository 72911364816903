@import 'globals/hack-variables.scss';

.eventcard {
  border-radius: 10px;
  width: 480px;
  height: 406px;
  background-color: white;
  color: black;
  display: inline-block;
  transition: 0.3s;
}

.past-eventcard {
  border-radius: 10px;
  width: 480px;
  height: 406px;
  background-color: white;
  color: black;
  display: inline-block;
  transition: 0.3s;
}

.eventcard:hover {
  background-color: rgb(245, 245, 245);
}

.parent-wrapper-event-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7fr 5fr;
  width: 480px;
  height: 406px;
}

.top-part-event-card-img {
  height: 270px;
  width: 480px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.top-text-event-card {
  padding-top: 8px;
  text-align: left;
  padding-left: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.bottom-text-event-card {
  padding-top: 8px;
  text-align: left;
  padding-left: 18px;
  float: bottom;
  font-weight: normal;
  font-size: 16px;
}

.popup-button-event-card {
  background-color: #233b92;
  border-radius: 30px;
  border: 1px solid #233b92;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 35px;
  padding-right: 35px;

  span {
    font-size: 20px;
  }
}

.popup-button-event-card:hover {
  background-color: #1e3179;
}

.popup-button-event-card-link {
  color: white;
}

.popup-button-event-card-link:hover {
  color: white;
}

.header-line-event-card {
  max-width: 70%;
  display: inline;
}

.popup-title-event-card {
  font-size: 2rem;
  font-weight: bold;
}

.modal-styling-event-card {
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
  left: auto;
  right: auto;
  background-color: rgb(245, 245, 245);
  color: black;
  border-radius: 10px;
  text-align: center;
  margin-left: 20vw;
  margin-right: 20vw;
  padding: 0px;
  margin-top: 10vh;
  height: 70vh;
  position: relative;
  z-index: 20;

  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }
}
.desc-text-event-card {
  font-size: 1.75rem;
  text-align: left;
  overflow-x: hidden;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.desc-text-wrapper-event-card {
  margin-top: 25px;
  overflow: auto;
  height: 40vh;
  padding: 15px;
  margin-bottom: 20px;

  p {
    font-size: 22px;
  }
}

.modal-subtext-date {
  font-size: 22px;
}

.modal-top-part {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #cccccc;
  padding-top: 20px;
  width: 100%;
  position: relative;
  z-index: 20;
}

.modal-bottom-part {
  border-top: 1px solid #cccccc;
  border-radius: 10px 10px 0px 0px;
}
.close-button-event-card {
  border: 0px;
  background-color: white;
  border: 1px solid #2b2b2b;
  border-radius: 4px;
  color: black;
  font-size: 30px;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 15px;
  right: 15px;
}

.close-button-event-card:hover {
  background-color: rgb(224, 224, 224);
}

@media (orientation: portrait) {
  .popup-title-event-card {
    font-size: 1.25rem;
  }

  .desc-text-event-card {
    text-align: left;
    overflow: auto;
  }

  .modal-styling-event-card {
    left: auto;
    right: auto;
    border-radius: 10px;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
    padding: 0px;
    margin-top: 10vh;
    height: 70vh;
    margin-bottom: 5vh;
  }

  .desc-text-wrapper-event-card {
    height: 30vh;
  }

  .close-button-event-card {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .eventcard {
    width: 300px;
    height: 300px;
    display: inline-block;
    transition: 0.3s;
  }

  .past-eventcard {
    width: 300px;
    height: 300px;
    display: inline-block;
    transition: 0.3s;
  }

  .parent-wrapper-event-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 7fr 5fr;
    width: 300px;
    height: 300px;
  }
  .top-part-event-card-img {
    width: 300px;
    height: 175px;
  }

  .top-text-event-card {
    padding-top: 6px;
    text-align: left;
    padding-left: 15px;
    font-size: 22px;
  }

  .bottom-text-event-card {
    padding-top: 6px;
    text-align: left;
    padding-left: 15px;
    float: bottom;
    font-size: 18px;
  }
}
