.team-title {
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
  color: rgba(43,43,43,1);
}

.team-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media screen and (max-width: 760px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}
