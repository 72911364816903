@import "src/globals/hack-variables.scss";

.footer {
  width: 100%;
  height: 80px;
  background: $blue-gradient;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 25px;
    filter: brightness(100);
  }
}

@media screen and (min-width: 768px) {
  .footer {
    justify-content: center;
    img {
      width: 40px;
      margin: 20px 40px;
    }
  }
}
