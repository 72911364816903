// HackUCI Styling variables

// Ember Style colors
$ember-blue: #2e3a4d;
$ember-purple: #41436a;
$ember-maroon: #984063;
$ember-red: #f64668;
$ember-orange: #fe9677;

// Hack (NEW) Color Palette
$darkest-blue: #233b92;
$dark-blue: #567aff;
$blue: #a6b9ff;
$light-blue: #d4ddff;
$red: #ff6969;
$light-red: #ff9595;
$lightest-red: #ffd2d2;
$sea-green: #a8dadc;
$light-grey: #F4F5F8;

$blue-gradient: linear-gradient(90deg, #1c3385 0%, #118afb 100%);

// Normal Colors
$black: #000000;
$white: #fefefe;
$dark-gray: #2e2e2e;
$hackUCIFont: Poppins, sans-serif;

// Spacings
$large-spacing: 4rem;
$medium-spacing: 2rem;
$small-spacing: 1rem;
$tiny-spacing: 0.5rem;
