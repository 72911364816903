// Helper CSS classes for use around the app
@import "src/globals/hack-variables.scss";

#root {
  background-color: $darkest-blue;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.box-shadow {
  box-shadow: 3px 10px 18px rgba(0, 0, 0, 0.1);
}

.rounded-corners {
  border-radius: 10px;
}

.fill-container {
  width: 100%;
  height: 100%;
}

.fill-view {
  width: 100vw;
  height: 100vh;
}
