@import "src/globals/hack-variables.scss";

.sponsor-hackuci {
  margin: 0px 0 40px 0;
}

.sponsor-hackuci input {
  font-weight: 600;
  font-size: 20px;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  background: linear-gradient(90deg, #FF928B 0%, #8494DA 100%);
  transition: 300ms;
  opacity: 1;
  &:hover {
    opacity: 85%;
  }
}

.sponsor-hackuci a {
  position: relative;
}

.sponsor-attendees {
  display: flex;
  justify-content: center;
  align-content: center;
  & span {
    font-size: 48px;
    padding-left: 10px;
  }
}

.zothacks-btn {
  background: linear-gradient(90deg, #2F9C95 0%, #ADD8AB 100%) !important;
}

.sponsor-hack-graph {
  padding-top: 30px;
}

.sponsor-stat-img {
  width: 60%;
  margin: 20px;
}

.sponsor-stats {
  display: inline-block;
  margin: 100px 0;
}

.sponsor-stat {
  display: inline-block;
  margin: 10px 50px;
  max-width: 140px;
  vertical-align: text-top;
}

.sponsor-stat p {
  font-size: 60px;
  margin: 0;
}

.sponsor-stat label {
  font-size: 20px;
  margin: 0;
}

.sponsor-hackuci p {
  margin: 0;
}

.sponsor-stat-hack-number {
  padding-top: 110px;
  padding-left: 10px;
  text-align: center;
  font-size: 120px;
}

.sponsor-stat-hack-label {
  padding-top: 230px;
  text-align: center;
  font-size: 60px;
}

.sponsor-content-anecdote p {
  margin: 0;
}

.sponsor-content-anecdote {
  grid-column: 3;
  border-radius: 10px;
  padding: 35px 35px 20px 35px;
  background-color: $light-grey;
  margin-bottom: 30px;
  text-align: left;
  font-size: 20px;
}

#Bianca {
  min-height: 230px;
  grid-row: 3;
}

#Lizzie {
  min-height: 190px;
}

#Cher {
  min-height: 200px;
  grid-row: 7;
}

.sponsor-previous {
  margin-top: 150px;
  font-size: 36px;
}

//commit update
@media screen and (max-width: 1200px) {
  .sponsor-content {
    display: block;
  }
  .sponsor-content-anecdote {
    font-size: 20px;
  }
  .sponsor-content-anecdote {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .sponsor-hackuci input {
    font-size: 16px;
    border-radius: 30px;
    padding: 10px 24px;
  }

  .sponsor-attendees {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    & img {
      width: 75px;
    }
    & span {
      font-size: 20px;
      padding-left: 10px;
    }
  }

  .sponsor-content {
    display: block;
  }
  .sponsor-hackuci a {
    margin: 0 20px;
  }
  .sponsor-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
  }
  .sponsor-stat {
    margin: 10px 12px;
  }
  .sponsor-stat p {
    font-size: 40px;
  }
  .sponsor-stat label {
    font-size: 16px;
  }
  .sponsor-content-anecdote {
    font-size: 15px;
  }
}
