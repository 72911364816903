@import 'src/globals/hack-variables.scss';

html {
  scroll-behavior: smooth;
}

.team-photo {
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 50%,
    #f4f5f8 50%,
    #f4f5f8 100%
  );
  img {
    filter: drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.5));
    border-radius: 10px;
    width: 85vw;
  }
}

.gray-background {
  background-color: #f4f5f8;
}

.half-gray-background {
  position: relative;
  height: 200px;
  background-color: #f4f5f8;
}

.about {
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  .about-content {
    margin: auto;

    .about-description {
      padding-top: 180px;
      text-align: center;
      h2 {
        font-weight: 500;
        margin-bottom: 24px;
      }
      p {
        font-size: 20px;
      }
    }

    .about-teams {
      padding-top: 0;
    }
  }
}
