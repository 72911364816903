@import "src/globals/hack-variables.scss";

.Recruitment {
	.container {
		padding: 5rem 0;
	}

	.col-lg {
		margin: 20px 30px;
	}

	.about h3 {
		color: $darkest-blue;
	}

	.committees {
		background-color: $light-grey;

		& > .container {
			padding: 10rem 0;
		}

		.committee {
			padding: 3rem 1rem;
		}

		img {
			height: 50px;
		}

		p,
		li {
			text-align: left;
		}

		h3 {
			margin: 30px 0;
		}

		.apply {
			background-color: $red;
			border: 0;
			border-radius: 50px;
			font-size: 20px;
			margin-top: 2rem;
			padding: 0.75rem 1.25rem;
			transition: background-color 0.25s ease;

			&:hover {
				background-color: $ember-red;
				transition: background-color 0.25s ease;
			}
		}
	}
}
