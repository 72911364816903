@import 'src/globals/hack-variables.scss';

.global-header {
  background: $blue-gradient;
  color: white;
  height: 20rem;
}

.header-image {
  height: 100%;
  background-image: url('~assets/hackshapes_header.png');
  background-repeat: no-repeat;
  background-size: 1920px auto;
  // keep laptop near center of header
  background-position: calc(50% - 400px + min(400px, 25vw)) 100%;
}

.header-overlay {
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (orientation: portrait) {
  .header-overlay {
    h1 {
      font-size: 3rem !important;
    }
  }
}
