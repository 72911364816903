.sponsor-list {
  text-align: center;

  .sponsor-list-item {
    display: inline-block;
    width: 300px;
    margin: 2rem;

    a {
      display: contents;
    }

    img {
      max-width: 100%;
      max-height: 150px;
    }
  }
}
