@import "globals/hack-variables.scss";
@import 'src/globals/hack-styles.scss';

body {
  background: $blue-gradient;
  background-repeat: repeat-y;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: $white;
  color: black;
  font-family: $hackUCIFont;
  overflow: hidden;

  h1 {
    font-size: 4.5rem;
    font-weight: 700;
  }
  h2 {
    font-size: 3rem;
    font-weight: 500;
  }
  h3 {
    font-size: 2.25rem;
  }
}

.bg-gray {
  background-color: $light-grey;
}

.container {
  padding-bottom: 7.5rem;
  padding-top: 7.5rem;
}

@media (min-width: 576px) {
  .container {
    padding-bottom: 11.25rem;
    padding-top: 11.25rem;
  }
}
