@import 'src/globals/hack-variables.scss';

.team-card {
  height: 300px;
  width: 200px;
  text-align: left;
  margin-right: 20px;

  .images-container {
    position: relative;
    margin-bottom: 0.5rem;

    .profpic {
      height: 200px;
      width: 200px;
      border-radius: 3px;
      overflow: hidden;
      max-height: 200px;
      object-fit: cover;
      object-position: 0 0px;
    }

    .linkedinLogo {
      position: absolute;
      right: 0px;
      z-index: 5;
      height: 30px;
      width: 30px;
    }
  }
}
@media screen and (max-width: 760px) {
  .team-card {
    height: 220px;
    width: 110px;

    .images-container {
      position: relative;

      .profpic {
        height: 120px;
        width: 110px;
        border-radius: 3px;
        overflow: hidden;
        max-height: 110px;
        object-fit: cover;
        object-position: 0 0px;
      }

      .linkedinLogo {
        position: absolute;
        right: 0px;
        z-index: 5;
        height: 30px;
        width: 30px;
      }
    }
    .name-sizing {
      font-size: 13px;
    }
  }
}

.footer h1 {
  margin-top: 0;
}
